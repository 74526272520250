/*global $, jQuery, alert, console*/

    "use strict";
    var isMobile = {
        Android: function() {
            return navigator.userAgent.match(/Android/i);
        },
        BlackBerry: function() {
            return navigator.userAgent.match(/BlackBerry/i);
        },
        iOS: function() {
            return navigator.userAgent.match(/iPhone|iPad|iPod/i);
        },
        Opera: function() {
            return navigator.userAgent.match(/Opera Mini/i);
        },
        Windows: function() {
            return navigator.userAgent.match(/IEMobile/i);
        },
        any: function() {
            return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows() );
        }
    };


    $(document).ready(function () {




        $('.sidenav').sidenav();
        $('.tabs').tabs();
        $('.collapsible').collapsible();
        $('.collapsible.expandable').collapsible({
            accordion: false
        });
        $('select').formSelect();
        $('.modal').modal();

        $('.open-newmodal').on('click', function() {
            var newModalId = $(this).attr('href');
            $('.modal.open').modal('close');
            setTimeout(function () {
                $(newModalId).modal('open')
            }, 1000);
        });

        if(window.location.hash.indexOf('Modal') >= 0) {
            $(window.location.hash).modal('open');
        }

        $('.navigation-part-wrap').fixTo('.modal-content', {
            top: 0
        });


        controlTopMenu();
        initRangeSlider();
        slickSliderControl();
        controlAnimatedScroll();
        controlNumberInputs();



        function controlNumberInputs() {
            $("#subscribeInput").keydown(function () {
                // Save old value.
                if (!$(this).val() || (parseInt($(this).val()) <= 2000000 && parseInt($(this).val()) >= 0))
                    $(this).data("old", $(this).val());
            });
            $("#subscribeInput").keyup(function () {
                // Check correct, else revert back to old value.
                if (!$(this).val() || (parseInt($(this).val()) <= 2000000 && parseInt($(this).val()) >= 0))
                    ;
                else
                    $(this).val($(this).data("old"));
            });

            $("#mailingInput").keydown(function () {
                // Save old value.
                if (!$(this).val() || (parseInt($(this).val()) <= 130 && parseInt($(this).val()) >= 0))
                    $(this).data("old", $(this).val());
            });
            $("#mailingInput").keyup(function () {
                // Check correct, else revert back to old value.
                if (!$(this).val() || (parseInt($(this).val()) <= 130 && parseInt($(this).val()) >= 0))
                    ;
                else
                    $(this).val($(this).data("old"));
            });
        }
        function controlTopMenu() {
            var scrollHeight = $(window).scrollTop();

            if(scrollHeight  > 0) {
                $('.menu').addClass('not-top');
            } else {
                $('.menu').removeClass('not-top');
            }
            $(window).scroll(function() {
                scrollHeight = $(window).scrollTop();

                if(scrollHeight  > 0) {
                    $('.menu').addClass('not-top');
                } else {
                    $('.menu').removeClass('not-top');
                }
            });
        }

        var goToBasketBtn = $('#buyTariffModal').find('#goToBasketBtn');
        var basketBtnHref = goToBasketBtn.attr('href');

        $('.buy__btn').on('click', function() {
            goToBasketBtn.attr('href', basketBtnHref + $(this).data('id-tarif'));
        });

        $('.hs_persona .wpcf7-select').on('change',function() {
            if ($(this).val() == 'Новый клиент') {
                $(this).closest('.s-contact__form-wrap').addClass('new-person')
            } else {
                $(this).closest('.s-contact__form-wrap').removeClass('new-person')
            }
        });

        var dataArray = [];
        $('.table__result .table__col').each(function () {
            if ($(this).hasClass('not-checked')) {
                return
            }
            var mailCount = parseInt($(this).find('.table__row-4').find('.text-line').text().replace(/\s/g, ''));
            var mailPrice = $(this).find('.table__row-3').find('.number-line').text();
            var tarifPrice = $(this).find('.table__row-2').find('.number-line').text();
            var tariffName = $(this).find('.table__row-1').find('.text-line').text();
            var btnId = $(this).find('.table__row-7').find('.buy__btn').data('id-tarif');
            var startTime = $(this).find('.table__row-6').find('.from-time').text();
            var endTime = $(this).find('.table__row-6').find('.to-time').text();
            var freeTariff = false;
            if ($(this).hasClass('col__green')) {
                mailPrice = 0;
                tarifPrice = 0;
                freeTariff = true;
            }
            dataArray.push({count : mailCount, mailPrice: mailPrice, tarifPrice: tarifPrice, tariffName: tariffName, freeTariff: freeTariff, btnId: btnId, startTime:startTime, endTime:endTime})
        });

        function controlTariffCalculator(subs, mails) {
            var finalMails = subs * mails;


            var tarifName = $('#calculatorTarifName');
            var tarifPrice = $('#calculatorTarifPrice .data');
            var tarifMailPrice = $('#calculatorMessagePrice .data');
            var tarifTime = $('#calculatorTarifTime .data');
            var buyBtn = $('#calculatorTarifBtn');




            for (var i = 0; i < dataArray.length; i++) {
                if (finalMails > dataArray[i].count && i == (dataArray.length - 1)) {
                    $('#calculatorSection .right-part').addClass('other-condition').removeClass('free-tariff');
                    buyBtn.data('id-tarif', '0');
                    return
                }
                if (finalMails > dataArray[i].count) {
                    continue;
                } else if (finalMails <= dataArray[i].count && dataArray[i].freeTariff) {
                    $('#calculatorSection .right-part').removeClass('other-condition').addClass('free-tariff');
                    tarifName.html('<span>' + dataArray[i].tariffName + '</span>');
                    tarifPrice.html('<span>' + dataArray[i].tarifPrice + ' руб/мес</span>');
                    tarifMailPrice.html('<span>' + dataArray[i].mailPrice + ' руб/мес</span>');
                    tarifTime.html('<span>' + dataArray[i].endTime  + '</span>');
                    buyBtn.data('id-tarif', '0');
                    return
                } else if (finalMails <= dataArray[i].count && !dataArray[i].freeTariff) {
                    $('#calculatorSection .right-part').removeClass('other-condition').removeClass('free-tariff');
                    tarifName.html('<span>' + dataArray[i].tariffName + '</span>');
                    tarifPrice.html('<span>' + dataArray[i].tarifPrice + ' руб/мес</span>');
                    tarifMailPrice.html('<span>' + dataArray[i].mailPrice + ' руб/мес</span>');
                    tarifTime.html('<span>' + dataArray[i].startTime  + '</span><span>' + dataArray[i].endTime + '</span>');
                    buyBtn.data('id-tarif', dataArray[i].btnId);
                    return
                }
            }

        };
        function initRangeSlider() {
            var subscribeInput = $('#subscribeInput');
            var mailingInput = $('#mailingInput');
            var subsNumber, mailsNumber;

            $('#subscribeRangeSlider').ionRangeSlider({
                min: 50,
                max: 2000000,
                from: 50,
                onStart: function (data) {
                    subscribeInput.val(data.from);
                    subsNumber = data.from;
                },
                onChange: function (data) {
                    subscribeInput.val(data.from);
                },
                onFinish: function (data) {
                    // fired on pointer release
                    subsNumber = data.from;
                    controlTariffCalculator(subsNumber, mailsNumber)
                },
                onUpdate: function (data) {
                    subsNumber = data.from;
                    controlTariffCalculator(subsNumber, mailsNumber)
                }
            });
            $('#mailingRangeSlider').ionRangeSlider({
                min: 1,
                max: 130,
                from: 1,
                onStart: function (data) {
                    mailingInput.val(data.from);
                    mailsNumber = data.from
                },
                onChange: function (data) {
                    mailingInput.val(data.from);
                },
                onFinish: function (data) {
                    // fired on pointer release
                    mailsNumber = data.from;
                    controlTariffCalculator(subsNumber, mailsNumber);
                },
                onUpdate: function (data) {
                    mailsNumber = data.from;
                    controlTariffCalculator(subsNumber, mailsNumber);
                    // fired on changing slider with Update method
                }
            });

            var subscribeRange = $('#subscribeRangeSlider').data("ionRangeSlider");
            var mailingRange =  $('#mailingRangeSlider').data("ionRangeSlider");

            subscribeInput.on('change', function() {
                var thisVal = $(this).val();
                subscribeRange.update({
                   from: thisVal
                });
            });

            mailingInput.on('change', function() {
                var thisVal = $(this).val();
                mailingRange.update({
                    from: thisVal
                });
            });
        }


        function addInlineSvg() {
            var mySVGsToInject = document.querySelectorAll('img.image_svg');
            SVGInjector(mySVGsToInject);
        }

        function controlAnimatedScroll() {
            $('.animated-scroll').on('click', function (e) {
                e.preventDefault();
                var linkHref = $(this).attr('href');
                if (linkHref == '#banerFormSection') {
                    $('html, body').animate({
                        scrollTop: $(linkHref).offset().top - 70
                    }, 500);
                } else {
                    $('html, body').animate({
                        scrollTop: $(linkHref).offset().top
                    }, 500);
                }
            });
        }


        function slickSliderControl() {
            // $('.s-testimonials__slider').on('beforeChange', function(event, slick, currentSlide, nextSlide){
            //     $('html, body').animate({
            //         scrollTop: $('#timonialsSection').offset().top - 50
            //     }, 500);
            // });

            $('.s-testimonials__slider').slick({
                arrows: true,
                dots: true,
                adaptiveHeight: true,
                prevArrow: '<div class="prev-btn"><img class="image_svg" src="/wp-content/themes/marketion/img/arrow-left.svg"></div>',
                nextArrow: '<div class="next-btn"><img class="image_svg" src="/wp-content/themes/marketion/img/arrow-right.svg"></div>',
                responsive: [
                    {
                        breakpoint: 600,
                        settings: {
                            arrows: false
                        }
                    }
                ]
            });

            if ($(window).width() <= 991) {
                $('.table__result').slick({
                    variableWidth: true,
                    arrows: false,
                    dots: true,
                    centerMode: false,
                    infinite: false,
                    slidesToScroll: 2
                })
            }
            $(window).resize(function() {

                if ($(window).width() <= 991 && !$('.table__result').hasClass('slick-slider')) {
                    $('.table__result').slick({
                        variableWidth: true,
                        arrows: false,
                        dots: true,
                        centerMode: false,
                        infinite: false,
                        slidesToScroll: 2
                    })
                } else if ($(window).width() > 991 && $('.table__result').hasClass('slick-slider')) {
                    $('.table__result').slick('unslick');
                }
            });
        }

        addInlineSvg();
        if (document.documentMode || /Edge/.test(navigator.userAgent)) {
            $('.navigation-part-wrap').css('position','relative');
        }
    });